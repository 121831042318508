/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.desk {
  max-width: calc(100% - 168px);
}
#home {
  width: 20.83333333vw;
  max-width: 300px;
  height: 4.42708333vw;
  max-height: 64px;
  margin-top: 36px;
}
.section--pre-footer #home {
  margin: 35px auto 25px;
}
#head {
  margin-top: 35px;
}
.cb-layout2 #head {
  width: 65.51724138%;
  margin-left: 17.24137931%;
}
#signet {
  left: 29px;
  top: 6px;
  width: 30px;
  height: 32px;
}
@media only screen and (max-width: 1023px) {
  #servicesLeft .meta,
  #servicesRight .meta {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1;
  }
  #servicesLeft .meta:before,
  #servicesRight .meta:before,
  #servicesLeft .meta:after,
  #servicesRight .meta:after {
    width: 10px;
    height: 1px;
  }
}
.section--footer .footer-content {
  float: left;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 70px 0 30px;
}
.vcard {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.15384615;
}
#social .meta {
  height: 37px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
#social .meta.service_facebook {
  width: 16px;
  margin-right: 18px;
  background-size: 19px 37px;
}
#social .meta.service_instagram {
  width: 31px;
  background-size: 31px 31px;
}
#cmsbox {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1;
  text-align: right;
}
.cb-goto-top {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 71px;
  width: 142px;
  border-top-left-radius: 142px;
  border-top-right-radius: 142px;
}
.cb-goto-top:after {
  background-image: url(/images/toplink-medium.svg);
  background-size: 8px 28px;
}
.container {
  padding: 15px 0 52.5px;
}
.cb-layout2 .container:not(.container--main) {
  padding-bottom: 35px;
}
.cb-goto-container-next {
  width: 44px;
  height: 44px;
  margin-left: calc(50% - 22px);
  margin-top: 17.5px;
}
.cb-layout2 .container.container--farwest {
  padding-bottom: 0;
}
.cb-layout2 .container.container--farwest .norm {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 1.11538462;
}
.cb-layout2 .container.container--farwest .line {
  width: 23.52941176%;
  margin-left: 38.23529412%;
  margin-right: 38.23529412%;
}
.cb-layout1 .left,
.cb-layout1 .right {
  float: left;
  width: 48.27586207%;
}
.cb-layout1 .right {
  float: right;
}
.wrapper {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
}
h1,
h2 {
  font-size: 19px;
  font-size: 1.9rem;
  line-height: 1.21052632;
  letter-spacing: 1px;
}
h3 {
  font-size: 42px;
  font-size: 4.2rem;
}
.container--main h3 {
  font-size: 53px;
  font-size: 5.3rem;
}
h4 {
  font-size: 23px;
  font-size: 2.3rem;
}
.loud {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.16666667;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
}
.area .part {
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
  width: 96.66666667%;
}
.area .tiny {
  width: 46.66666667%;
}
.area > .slim {
  width: 50%;
}
.area > .slim .foot,
.area > .slim .part {
  margin-right: 3.33333333%;
  margin-left: 3.33333333%;
}
.area > .slim .part {
  width: 93.33333333%;
}
.area > .slim .tiny {
  width: 43.33333333%;
}
.cb-layout1 .area.main,
.cb-layout1 .area.side {
  width: 100%;
}
.cb-layout1 .area.main > .unit,
.cb-layout1 .area.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area.main .foot,
.cb-layout1 .area.side .foot {
  margin-right: 3.33333333%;
  margin-left: 3.33333333%;
}
.cb-layout1 .area.main .part,
.cb-layout1 .area.side .part {
  margin-right: 3.33333333%;
  margin-left: 3.33333333%;
  width: 93.33333333%;
}
.cb-layout1 .area.main .tiny,
.cb-layout1 .area.side .tiny {
  width: 43.33333333%;
}
.cb-layout1 .area.main > .slim .part,
.cb-layout1 .area.side > .slim .part {
  width: 93.33333333%;
}
.cb-layout1 .area.main > .slim .tiny,
.cb-layout1 .area.side > .slim .tiny {
  width: 43.33333333%;
}
.area {
  width: 103.44827586%;
  margin-left: -1.72413793%;
}
.area .unit {
  margin-top: 20px;
  margin-bottom: 20px;
}
.area .unit .part {
  margin-top: 15px;
  margin-bottom: 15px;
}
.cb-layout1 .area.main,
.cb-layout1 .area.side {
  width: 107.14285714%;
  margin-left: -3.57142857%;
}
.area.main .unit:first-child {
  margin-top: 0;
}
.area.main .unit:first-child .part:first-child {
  margin-top: 0;
}
.area .unit.seam.wide .part.tiny {
  width: 30%;
}
.area .unit.seam.slim {
  width: 33.33333333%;
}
.area .unit.seam.slim .part {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
.area .unit.flat.wide {
  width: 66.66666667%;
  margin-left: 16.66666667%;
  margin-right: 16.66666667%;
}
.area .unit.flat.wide .part {
  margin-left: 2.5%;
  margin-right: 2.5%;
}
.area .unit.flat.wide .part.tall {
  width: 95%;
}
.area .unit.flat.wide .part.tiny {
  width: 45%;
}
.area .unit.flat.slim {
  width: 33.33333333%;
}
.area .unit.flat.slim .part {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
.unit.cb-album.cb-slideshow {
  width: 96.66666667%;
  margin-left: 1.66666667%;
  margin-right: 1.66666667%;
}
.unit.cb-album.cb-slideshow.slim {
  width: 46.66666667%;
}
.unit.cb-album.cb-slideshow .part.foto {
  width: calc(100% - 15px);
  margin: 15px 15px 0 0;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #BA894B;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #BA894B;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #986e3a;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  display: block;
  background-color: #BA894B;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 36px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  color: #fff;
  background-color: #BA894B;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  background-color: #BA894B;
}
.aspt {
  margin-right: 0.5em;
}
div.foto .body-non-mobile {
  padding-bottom: 50px;
}
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  width: 21px;
}
.prev > .prev,
.next > .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  height: 41px;
  padding: 0 0;
}
.cb-strips .prev,
.cb-slideshow .prev > .cb-hybrid {
  background-size: 21px 41px;
}
.cb-strips .next,
.cb-slideshow .next > .cb-hybrid {
  background-size: 21px 41px;
}
.cb-slideshow .ctrl > .prev {
  margin-right: 25px;
}
/*# sourceMappingURL=./screen-medium.css.map */